<div class="modal-header">
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="dismissFeedback()"
  >
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" id="myModalLabel">
    {{ 'participant.app-feedback.title' | translate }}
  </h4>
</div>
<div class="modal-body d-flex flex-column">
  <div class="row">
    <div class="col">
      <p [innerHTML]="'participant.app-feedback.description' | translate"></p>
    </div>
  </div>
  <div class="row flex-1 mt-5 justify-content-center smileys">
    <div class="col-auto mt-1">
      <i
        class="fas fa-smile-beam fa-4x show-cursor"
        [class.active-smile]="activeSmiley[0]"
        alt="Enjoyed Beyond.Host"
        (click)="smileyPressed(0)"
      ></i>
    </div>
    <div class="col-auto mt-1">
      <i
        class="fas fa-frown fa-4x show-cursor"
        [class.active-angry]="activeSmiley[1]"
        alt="Did not enjoy Beyond.Host"
        (click)="smileyPressed(1)"
      ></i>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <button
        mdbBtn
        [block]="true"
        class="custom-btn"
        mdbWavesEffect
        (click)="submitFeedback()"
        [disabled]="noSmileySelected"
      >
        {{ 'global.submit' | translate }}
      </button>
    </div>
  </div>
</div>
