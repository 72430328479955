import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { BhToastService } from 'projects/shared/services/bh-toast.service';
import { GlobalService } from 'projects/shared/services/global.service';
import { LocalizationService } from 'projects/shared/services/localization.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip, take } from 'rxjs/operators';

import {
  IFrameMessage,
  MessageType,
} from '../../../ionic/src/app/models/iframe-event.model';
import { Answer } from '../../../shared/models/answer.model';
import { Voting } from '../../../shared/models/voting.model';
import { AnswerService } from '../../../shared/services/answer.service';
import { ConnectionService } from '../../../shared/services/connection.service';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { ParticipantService } from '../../../shared/services/participant.service';
import { PlayerService } from '../../../shared/services/player.service';
import { VotingService } from '../../../shared/services/voting.service';
import { environment } from '../environments/environment';
import { AndroidSmartBannerComponent } from './components/android-smart-banner/android-smart-banner.component';
import { VotingParticipantComponent } from './components/voting-participant/voting/voting-participant.component';
import { AppFeedbackService } from './services/app-feedback.service';
import { MessageEventService } from './services/message-event.service';
import { SmartbannerService } from './services/smartbanner.service';

@Component({
  selector: 'app-participant-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public isFullyBrowser = 'fully' in window;

  private TITLE = environment.title;

  private connectionSub: Subscription;
  private votingSubscription: Subscription;
  private voting: Voting = undefined;
  private votingModalRef: MDBModalRef = undefined;
  private displayVotingModal = true; // to guarantee that only one modal is displayed

  private shouldDisplaySmartBanner = true;
  private smartBannerModalRef: MDBModalRef;

  private routeSubscription: Subscription;
  private langSubscription: Subscription;

  constructor(
    private _titleService: Title,
    private _votingService: VotingService,
    private _modalService: MDBModalService,
    private _answerService: AnswerService,
    private toastService: BhToastService,
    private _environmentService: EnvironmentService,
    private connectionService: ConnectionService,
    private messageEventService: MessageEventService,
    private participantService: ParticipantService,
    private localStorageService: LocalStorageService,
    private playerService: PlayerService,
    private loggerService: LoggerService,
    private globalService: GlobalService,
    private modalService: MDBModalService,
    private smartBannerService: SmartbannerService,
    private localizationService: LocalizationService,
    private router: Router,
    private appFeedbackService: AppFeedbackService
  ) {
    this.langSubscription = this.localizationService.currentLang$.subscribe(
      (currentLang) => {
        this.messageEventService.postMessage(
          { lang: currentLang },
          MessageType.LANGUAGE
        );
      }
    );

    this.messageEventService.iframeMessageEventSubject.subscribe(
      (message: IFrameMessage) => {
        this.handleIframeMessage(message);
      }
    );
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      // Initially initialize the smart banner after some delay.
      // The delay is necessary because we need to make sure that in the case of the Ionic app,
      // we should have already received an iframe event telling us that it is the Ionic app.
      // In case of the ionic app the smart banner will be hidden, but if not it stays visible.
      this.setupSmartBanner();
    }, 2000);
  }

  private displayOrHideAndroidSmartBanner(url: string): void {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1 && ua.indexOf('mobile') > -1;

    if (
      this.shouldDisplaySmartBanner &&
      !this.globalService.isIonicApp() &&
      isAndroid
    ) {
      const firstParamIndex = url.indexOf('?');
      const pathSegmentsWithoutUrlParams = url.substring(
        1,
        firstParamIndex > -1 ? firstParamIndex : undefined
      );

      this.smartBannerService.createSmartBanner(pathSegmentsWithoutUrlParams);

      this.smartBannerModalRef?.hide();
      this.smartBannerModalRef = this.modalService.show(
        AndroidSmartBannerComponent,
        {
          backdrop: false,
          keyboard: true,
          focus: true,
          show: false,
          ignoreBackdropClick: false,
          class: 'modal-frame modal-bottom',
          containerClass: 'modal-frame',
          animated: true,
        }
      );
      this.smartBannerModalRef.content.smartBannerConfirmed
        .pipe(take(1))
        .subscribe(() => {
          this.shouldDisplaySmartBanner = false;
        });
      this.smartBannerModalRef.content.smartBannerClosed
        .pipe(take(1))
        .subscribe(() => {
          this.shouldDisplaySmartBanner = false;
        });
    } else {
      this.smartBannerModalRef?.hide();
    }
  }

  private handleIframeMessage(message: IFrameMessage): void {
    const meetingId = this.localStorageService.loadMeeting();

    this.loggerService.logDebug(
      'Participant frontend: received window message.',
      JSON.stringify(message)
    );

    switch (message.type) {
      case MessageType.PUSH_TOKEN:
        const token = message.data.token;

        this.loggerService.logDebug(
          'Participant frontend: save the push token of the ionic-participant app in DB.',
          token
        );

        this.participantService
          .registerIonicAppForPushNotifications(
            meetingId,
            token,
            this.localizationService.currentLangKey
          )
          .pipe(take(1))
          .subscribe(
            (pushToken) => {
              this.localStorageService.savePushToken(pushToken.value);
              this.localStorageService.saveSubmittedLastFeedback(
                pushToken.lastFeedbackSubmitted
              );
              this.messageEventService.postMessage(
                {
                  lastFeedbackSubmitted: pushToken.lastFeedbackSubmitted,
                  lastFeedbackChoice: pushToken.lastFeedbackChoice,
                },
                MessageType.FEEDBACK_SUBMITTED
              );
              this.logPushTokenRegistrationSuccess();
            },
            (error: HttpErrorResponse) => {
              if (error.status === 409) {
                // This push token is already registered.
                this.logPushTokenRegistrationSuccess();
              } else {
                this.loggerService.logDebugError(
                  'Participant frontend: saving the push token of the ionic-participant app in DB failed.',
                  JSON.stringify(error)
                );
              }
            }
          );
        break;
      case MessageType.GONG_NOTIFICATION:
        this.loggerService.logDebug(
          'Participant frontend: play gong sound and show toast message.'
        );

        if (message.data.playSound) {
          this.playerService.playGongSound();
        }

        const roomName = this._environmentService.room.name;
        const toastMessage =
          'Your meeting in room ' + roomName + ' called you.';

        this.toastService.info(toastMessage);
        break;
      case MessageType.IS_IONIC:
        this.localStorageService.saveIsIonicApp(message?.data?.isIonic);
        // Update (hide) the smart banner because it's the ionic app.
        this.setupSmartBanner();
        break;
      case MessageType.FEEDBACK:
        this.appFeedbackService.openFeedbackModal(message.data);
        break;
      default:
    }
  }

  private setupSmartBanner(): void {
    // Initialize the smart banner with the current (static) route first, as no navigation-end event may be received (e.g. during reload).
    this.displayOrHideAndroidSmartBanner(this.router.url);

    // Listen to route changes and initialize the smart banner with the current route.
    this.routeSubscription?.unsubscribe();
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.displayOrHideAndroidSmartBanner(event.url);
      }
    });
  }

  private logPushTokenRegistrationSuccess(): void {
    this.loggerService.logDebug(
      'Participant frontend: saving the push token of the ionic-participant app in DB succeed.'
    );
  }

  public ngOnInit(): void {
    this._titleService.setTitle(this.TITLE);

    // Subscribe to voting websocket to receive votings
    this.votingSubscription = this._votingService.voting$.subscribe(
      (voting) => {
        if (voting) {
          if (voting.active) {
            this.initVoting(voting);
          } else {
            if (this.votingModalRef) {
              this.votingModalRef.hide();
              this.displayVotingModal = true;
            }
          }
        }
      }
    );

    this.connectionSub = this.connectionService.connectionObservable
      .pipe(distinctUntilChanged(), skip(1))
      .subscribe((online) => {
        if (online) {
          this._environmentService.startWebSocketConnection(false);
          this._votingService.startVotingWebSocketConnection(true);
        }
      });
  }

  public ngOnDestroy(): void {
    this.votingSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.langSubscription?.unsubscribe();
    this.connectionSub?.unsubscribe();
  }

  private initVoting(voting: Voting): void {
    // Verify that Participant has not voted yet
    if (localStorage.getItem('LastVotingId') === voting.id) {
      return;
    }

    this.voting = voting;
    // close open modals
    if (this.voting.active && this.displayVotingModal) {
      this.displayVotingModal = false;
      this.votingModalRef = this._modalService.show(
        VotingParticipantComponent,
        {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-dialog-centered',
          animated: true,
          data: {
            voting: this.voting,
          },
        }
      );
      this.votingModalRef.content.answer.subscribe((answer: Answer) => {
        const votingId = this.voting.id;
        if (this.voting.active && votingId && answer) {
          this._answerService.createAnswer(answer, votingId).subscribe((_) => {
            this.toastService.success('participant.create-answer.success');
            // Store the VotingId to deny multiple votes from the same particpant (Can be bypassed by clearing the cache/private tab)
            localStorage.setItem('LastVotingId', votingId);
          });
        }
      });
    }
  }
}
