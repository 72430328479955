import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'projects/shared/services/local-storage.service';
import { AppFeedbackService } from '../../services/app-feedback.service';

@Component({
  selector: 'app-meeting-ended-page',
  templateUrl: './meeting-ended-page.component.html',
  styleUrls: ['./meeting-ended-page.component.scss'],
})
export class MeetingEndedPageComponent implements OnInit {
  constructor(
    private localStorageService: LocalStorageService,
    private appFeedbackService: AppFeedbackService
  ) {}

  public ngOnInit(): void {
    setTimeout(() => this.appFeedbackService.openFeedbackModal(), 300);
  }

  public onBackHomePressed(): void {
    this.localStorageService.removeMeeting();
  }
}
