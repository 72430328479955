<div class="modal-header">
  <!-- Vote now -->
  <ng-container *ngIf="!voted">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'participant.voting.title' | translate }}
    </h4>
  </ng-container>
  <!-- END Vote now -->
  <!-- Waiting for results -->
  <ng-container *ngIf="voted">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'participant.voting.waiting-for-results' | translate }}
    </h4>
  </ng-container>
  <!-- END Waiting for results -->

  <div
    *ngIf="voted"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </div>
</div>
<!-- Modal Body -->
<div class="modal-body pt-0" style="height: 100%">
  <!--Progress-Ring time-->
  <app-progress-ring
    *ngIf="!voting.isInfinite"
    [isVotingActive]="voting.active"
    [remainingTime]="voting.timer"
  ></app-progress-ring>
  <div
    class="d-flex justify-content-center"
    *ngIf="voting.isInfinite"
    style="font-size: 5rem"
  >
    &#8734;
  </div>
  <!--Questions-->
  <ng-container *ngIf="!voted">
    <ng-container
      *ngFor="let answer of answers; let idx = index; let first = first"
    >
      <div *ngIf="idx % 2 == 0" class="row" [ngClass]="first ? '' : 'pt-3'">
        <div class="col w-100">
          <div
            class="card"
            mdbWavesEffect
            [ngClass]="
              answer.isActive
                ? 'custom-btn-activated'
                : 'custom-btn-deactivated'
            "
            (click)="setSelectedCardValue(answer, idx)"
          >
            <div class="card-body text-center font-weight-bold">
              {{ answer.value }}
            </div>
          </div>
        </div>
        <!-- To guarantee that the last entry has the same width as the other answers -->
        <ng-container *ngIf="idx + 1 == answers.length">
          <div class="col"></div>
        </ng-container>
        <!-- END To guarantee that the last entry has the same width as the other answers -->
        <ng-container *ngIf="idx + 1 < answers.length">
          <div class="col w-100">
            <div
              class="card"
              mdbWavesEffect
              [ngClass]="
                answers[idx + 1].isActive
                  ? 'custom-btn-activated'
                  : 'custom-btn-deactivated'
              "
              (click)="setSelectedCardValue(answers[idx + 1], idx + 1)"
            >
              <div class="card-body text-center font-weight-bold">
                {{ answers[idx + 1].value }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <!--END Questions-->
  <!-- Your vote -->
  <ng-container *ngIf="voted">
    <p class="text-center">
      {{ 'participant.voting.voted-for-answer' | translate }}
      {{ currentSelectedAnswer.value }}
    </p>
  </ng-container>
  <!-- END Your vote -->

  <!--Confirm button-->
  <ng-container *ngIf="!voted">
    <div class="container mt-2">
      <button
        mdbBtn
        mdbWavesEffect
        class="w-100 custom-btn"
        (click)="confirmClicked()"
      >
        {{ 'global.confirm' | translate }}
      </button>
    </div>
  </ng-container>
</div>
