import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from 'projects/shared/services/auth.service';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { GlobalService } from 'projects/shared/services/global.service';
import { LocalStorageService } from 'projects/shared/services/local-storage.service';
import { LocalizationService } from 'projects/shared/services/localization.service';
import { VotingService } from 'projects/shared/services/voting.service';
import { Observable } from 'rxjs/internal/Observable';
import { MessageEventService } from '../services/message-event.service';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root',
})
export class MeetingUpcomingEndedGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService,
    private _environmentService: EnvironmentService,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<boolean> {
    // Check if the meetingId is set in the URI
    let meetingId = route.queryParamMap.get('id');
    if (!meetingId) {
      // Check if the meetingId is set in the LocalStorage
      if (this._environmentService.checkSettingsParticipant()) {
        meetingId = this._localStorageService.loadMeeting();
      }
      // meetingId is neither stored nor part of the URI
      if (!meetingId) {
        this._router.navigate(['']);
        return of(false);
      }
    }
    return of(true);
  }
}
