<div class="container">
  <div class="row h-20">
    <div class="col h-100 d-flex justify-content-center">
      <img
        src="./../../../assets/BeyondHostLogo.png"
        class="w-100 h-100 object-fit-scale-down"
      />
    </div>
  </div>
  <div class="row h-55 pb-1">
    <div class="col h-100">
      <app-participant-carousel></app-participant-carousel>
    </div>
  </div>
  <ng-container>
    <div class="row h-5">
      <div
        class="col h-100 countdown d-flex align-items-center justify-content-center font-weight-bold"
      >
        {{ 'participant.countdown' | translate }}
      </div>
    </div>
    <div class="row h-5">
      <div
        class="col h-100 countdown d-flex align-items-center justify-content-center"
      >
        {{ countdownLabel }}
      </div>
    </div>
  </ng-container>
  <div class="row h-15">
    <div class="col h-100 d-flex justify-content-center align-items-center p-3">
      <app-back-to-start></app-back-to-start>
    </div>
  </div>
</div>
