import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalizationService } from 'projects/shared/services/localization.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { LocalStorageService } from 'projects/shared/services/local-storage.service';

@Component({
  selector: 'master-meeting-upcoming-page',
  templateUrl: './meeting-upcoming-page.component.html',
  styleUrls: ['./meeting-upcoming-page.component.scss'],
})
export class MeetingUpcomingPageComponent implements OnInit, OnDestroy {
  private startTime: number;
  private meetingId: string;
  private timeoutId;

  countdownLabel: string = '';

  constructor(
    private localizationService: LocalizationService,
    private localStorageService: LocalStorageService,
    private environmentService: EnvironmentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.meetingId = this.localStorageService.loadMeeting();
    this.startTime = Number(this.localStorageService.loadMeetingStartDate());
    this.checkMeetingStartTime();
    if (this.startTime && this.meetingId) {
      this.setCountdownLabel(0);
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }

  private setCountdownLabel(timeoutTime: number): void {
    this.timeoutId = setTimeout(() => {
      const now = moment().valueOf() / 1000;
      const startDateTs = this.startTime;
      let delta = startDateTs - now;
      if (delta <= 0) {
        this.checkMeetingStartTime();
      } else {
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta) % 60;
        this.countdownLabel =
          days +
          ' ' +
          _.startCase(
            _.toLower(
              this.localizationService.getTranslationForKey('global.days')
            )
          ) +
          '  ' +
          hours +
          ' ' +
          _.startCase(
            _.toLower(
              this.localizationService.getTranslationForKey('global.hours')
            )
          ) +
          '  ' +
          minutes.toString().padStart(2, '0') +
          ' ' +
          _.startCase(
            _.toLower(
              this.localizationService.getTranslationForKey('global.minutes')
            )
          ) +
          '  ' +
          seconds.toString().padStart(2, '0') +
          ' ' +
          _.startCase(
            _.toLower(
              this.localizationService.getTranslationForKey('global.seconds')
            )
          );

        this.timeoutId = this.setCountdownLabel(1000);
      }
    }, timeoutTime);
  }

  checkMeetingStartTime(): void {
    this.environmentService
      .getMeetingBasic(this.meetingId)
      .subscribe((meeting: any) => {
        this.startTime = meeting.startTime;
        this.meetingId = meeting.id;
        this.localStorageService.saveMeeting(meeting.id);
        this.localStorageService.saveMeetingStartDate(meeting.startTime);
        if (meeting.active) {
          this.router.navigate(['/main']);
        } else {
          this.timeoutId = this.setCountdownLabel(
            Math.floor(Math.random() * 1000) + 1
          );
        }
      });
  }
}
