import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalService } from 'ng-uikit-pro-standard';
import {
  IFrameMessage,
  MessageType,
} from 'projects/ionic/src/app/models/iframe-event.model';
import { environment } from 'projects/participant-app/src/environments/environment';
import { BhToastService } from 'projects/shared/services/bh-toast.service';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { GlobalService } from 'projects/shared/services/global.service';
import { LocalStorageService } from 'projects/shared/services/local-storage.service';
import { LoggerService } from 'projects/shared/services/logger.service';
import { validateQrScanResult } from 'projects/shared/util/util';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppFeedbackService } from '../../services/app-feedback.service';
import { MessageEventService } from '../../services/message-event.service';
import { QrCodeScannerModalComponent } from '../qr-code-scanner-modal/qr-code-scanner-modal.component';

@Component({
  selector: 'master-pin-login',
  templateUrl: './pin-login.component.html',
  styleUrls: ['./pin-login.component.scss'],
})
export class PinLoginComponent implements OnInit {
  public pinAsNumber: number;
  public isCheckingPin: boolean = false;
  public readonly PIN_LENGTH = environment.loginPinLength;

  private qrScanCompleteSubscription: Subscription;

  constructor(
    private environmentService: EnvironmentService,
    private loggerService: LoggerService,
    private toastService: BhToastService,
    private localStorageService: LocalStorageService,
    private modalService: MDBModalService,
    private messageEventService: MessageEventService,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
    private appFeedbackService: AppFeedbackService
  ) {
    this.messageEventService.iframeMessageEventSubject.subscribe(
      (message: IFrameMessage) => {
        if (message.type == MessageType.QR_SCAN_RESULT) {
          const scanResult: { hasContent: boolean; content: string } =
            message?.data?.result;

          if (validateQrScanResult(scanResult)) {
            this.handleValidQrCode(scanResult.content);
          } else {
            this.handleInvalidQrCode();
          }
        }
      }
    );
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params) => params.showFeedback)
      )
      .subscribe(() => this.appFeedbackService.openFeedbackModal());
  }

  public joinMeeting(): void {
    this.isCheckingPin = true;

    // Add missing leading zeros.
    const pin = String(this.pinAsNumber).padStart(this.PIN_LENGTH, '0');

    this.environmentService.joinMeeting(pin).subscribe(
      (response) => {
        this.localStorageService.saveMeeting(response.meetingId);
        this.isCheckingPin = false;
        window.open(
          `/main/home?id=${response.meetingId}&key=${response.meetingApiKey}`,
          '_self'
        );
      },
      (error: HttpErrorResponse) => {
        this.loggerService.logDebug(
          'PinLoginComponent: joinMeeting() error',
          error
        );
        this.isCheckingPin = false;
        switch (error.status) {
          case 404:
            this.toastService.error(
              'participant.start-page.pin-login.error.message',
              'participant.start-page.pin-login.error.title'
            );
            break;
          // Meeting ended
          case 420:
            this.toastService.error(
              'participant.start-page.pin-login.ended.message',
              'participant.start-page.pin-login.ended.title'
            );
            break;
        }
        this.loggerService.logDebug(
          'Could not join meeting by meeting PIN',
          error
        );
      }
    );
  }

  public scanQrCode(): void {
    if (this.globalService.isIonicApp()) {
      this.messageEventService.postMessage({}, MessageType.START_QR_SCAN);
    } else {
      const modal = this.modalService.show(QrCodeScannerModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-fluid modal-dialog-centered',
      });

      this.qrScanCompleteSubscription = modal.content.scanCompleted.subscribe(
        (result: string) => {
          if (validateQrScanResult({ hasContent: true, content: result })) {
            this.loggerService.logDebug(
              'PinLoginComponent: web scanner => valid QR code',
              result
            );
            modal?.hide();
            this.handleValidQrCode(result);
          } else {
            this.loggerService.logDebug(
              'PinLoginComponent: web scanner => INVALID QR code',
              result
            );
            this.handleInvalidQrCode();
          }
        }
      );

      modal.content.closed.pipe(take(1)).subscribe(() => {
        this.qrScanCompleteSubscription?.unsubscribe();
      });
    }
  }

  private handleValidQrCode(result: string): void {
    window.location.replace(result);
  }

  private handleInvalidQrCode(): void {
    this.toastService.error('participant.start-page.wrong-qr-code');
  }
}
