<div class="modal-content">
  <div class="modal-body p-0">
    <div id="close-button">
      <i class="fas fa-window-close" (click)="cancelButtonClicked()"></i>
    </div>
    <participant-qr-code-scanner
      (scanCompleted)="scanCompleted.emit($event)"
      (scanError)="scanErrorHandler($event)"
    ></participant-qr-code-scanner>
  </div>
</div>
