import { Injectable, OnDestroy } from '@angular/core';
import { LocalizationService } from 'projects/shared/services/localization.service';
import { Subject } from 'rxjs';

import {
  IFrameMessage,
  MessageType,
} from '../../../../ionic/src/app/models/iframe-event.model';

@Injectable({
  providedIn: 'root',
})
export class MessageEventService implements OnDestroy {
  public messageEventNameRegex = new RegExp('bh-participant');
  public iframeMessageEventSubject = new Subject<IFrameMessage>();

  private messageEventListener: (event: any) => void;

  constructor(private localizationService: LocalizationService) {
    this.messageEventListener = (event) => {
      if (
        event.data?.name &&
        this.messageEventNameRegex.test(event.data.name)
      ) {
        this.iframeMessageEventSubject.next(
          Object.assign(new IFrameMessage(), event.data)
        );
      }
    };

    window.addEventListener('message', this.messageEventListener);

    this.postMessage({}, MessageType.READY);
    this.postMessage(
      { lang: this.localizationService.currentLangKey },
      MessageType.LANGUAGE
    );
  }

  public ngOnDestroy(): void {
    window.removeEventListener('message', this.messageEventListener);
  }

  public postMessage(data: any, type: MessageType): void {
    window.parent.postMessage(
      IFrameMessage.construct('bh-participant', type, data),
      '*'
    );
  }
}
