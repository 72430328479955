import { Component, OnDestroy, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { BhToastService } from 'projects/shared/services/bh-toast.service';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { Answer } from '../../../../../../shared/models/answer.model';
import { SelectedCard } from '../../../../../../shared/models/selectedCard.model';
import { Voting } from '../../../../../../shared/models/voting.model';
import { VotingCountdownService } from '../../../../../../shared/services/voting-countdown.service';
import { VotingService } from '../../../../../../shared/services/voting.service';

@Component({
  selector: 'app-voting-participant',
  templateUrl: './voting-participant.component.html',
  styleUrls: ['./voting-participant.component.scss'],
})
export class VotingParticipantComponent implements OnInit, OnDestroy {
  public voting: Voting;

  public remainingTime = 0;
  public answers: SelectedCard[] = [];
  public currentSelectedAnswer: SelectedCard = undefined;
  public previousSelectedAnswerIndex: number = undefined;

  public possibleQuestions: Answer[];
  public voted = false;

  private answer = new Subject<Answer>();
  private votingSubscription: Subscription;

  constructor(
    private _votingCountdownService: VotingCountdownService,
    private _votingService: VotingService,
    private toastService: BhToastService,
    public modalRef: MDBModalRef
  ) {}

  public ngOnInit(): void {
    if (this.voting) {
      if (this.voting.active) {
        this.setLayout();
      }
    }
  }

  public ngOnDestroy(): void {
    this._votingCountdownService.stopCountdownEarly();
    this.votingSubscription?.unsubscribe();
  }

  private setLayout(): void {
    this._votingService
      .computeRemainingTimer(this.voting)
      .pipe(take(1))
      .subscribe((remainingTime) => {
        this._votingCountdownService.startCountdown(
          this.voting.timer,
          remainingTime
        );
      });
    for (let index = 0; index < this.voting.numberOfAnswers; index++) {
      this.answers.push({
        isActive: false,
        value: index + 1,
      });
    }
  }

  public setSelectedCardValue(selectedCard: SelectedCard, index: number): void {
    selectedCard.isActive = !selectedCard.isActive;
    // Set the current answer
    if (selectedCard.isActive) {
      this.currentSelectedAnswer = selectedCard;
    } else {
      this.currentSelectedAnswer = undefined;
    }

    // Deactivate the previous selecetd Answer
    if (this.previousSelectedAnswerIndex !== undefined) {
      if (
        !(
          this.answers[this.previousSelectedAnswerIndex] ===
          this.currentSelectedAnswer
        )
      ) {
        this.answers[this.previousSelectedAnswerIndex].isActive = false;
      }
    }

    // Set the new 'previous answer' to the current selected Answer
    if (this.currentSelectedAnswer) {
      this.previousSelectedAnswerIndex = index;
    } else {
      this.previousSelectedAnswerIndex = undefined;
    }
  }

  public confirmClicked(): void {
    if (this.currentSelectedAnswer) {
      this.voted = true;
      this.answer.next({
        vote: this.currentSelectedAnswer.value,
      });
    } else {
      this.toastService.error(
        'participant.voting.send-voting.error',
        'global.error'
      );
    }
  }
}
