import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';
import { CameraDevice } from 'html5-qrcode/esm/camera/core';
import {
  Html5QrcodeResult,
  Html5QrcodeSupportedFormats,
} from 'html5-qrcode/esm/core';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { BhToastService } from '../../../../../shared/services/bh-toast.service';
import { LoggerService } from '../../../../../shared/services/logger.service';

@Component({
  selector: 'participant-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeScannerComponent implements OnDestroy {
  @Output() public scanCompleted = new EventEmitter<string>();
  @Output() public scanError = new EventEmitter<string>();

  @ViewChild('container', { static: true })
  private readerContainer: ElementRef;

  public selectedCamera$: BehaviorSubject<CameraDevice> = new BehaviorSubject(
    null
  );
  public cameras$: BehaviorSubject<CameraDevice[]> = new BehaviorSubject(null);

  private html5QrCode: Html5Qrcode;
  private subscription: Subscription;

  private inTransition = false;

  constructor(
    private toastService: BhToastService,
    private loggerService: LoggerService
  ) {}

  public ngAfterViewInit(): void {
    if (this.html5QrCode) {
      this.html5QrCode.stop().then(() => this.askForCameraPermissionAndStart());
    } else {
      this.askForCameraPermissionAndStart();
    }

    this.setViewHeight();

    this.subscription = fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        filter(
          () =>
            this.html5QrCode &&
            this.selectedCamera$.value &&
            this.html5QrCode.getState() === Html5QrcodeScannerState.SCANNING
        )
      )
      .subscribe(() => {
        this.setViewHeight();
        this.html5QrCode
          .stop()
          .then(() => {
            this.startScanner(this.selectedCamera$.value.id);
          })
          .catch((error) => {
            this.inTransition = true;
            this.loggerService.logProdError('ON WINDOW RESIZE ERROR: ', error);
            this.toastService.error(
              'participant.qr-code-scanner.unknown-error'
            );
            this.scanError.emit('ON WINDOW RESIZE ERROR: ' + error);
          });
      });
  }

  public ngOnDestroy(): void {
    this.loggerService.logDebug(
      'ngOnDestroy',
      this.html5QrCode.getState(),
      this.inTransition
    );
    // Fixes in transition error that will lead to problems with md bootstrap
    if (!this.inTransition) {
      this.html5QrCode?.stop();
    }
    this.subscription?.unsubscribe();
  }

  private setViewHeight(): void {
    if (!this.readerContainer) {
      return;
    }

    const readerContainer = this.readerContainer.nativeElement;
    readerContainer.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
  }

  private askForCameraPermissionAndStart() {
    Html5Qrcode.getCameras()
      .then((cameras) => {
        if (cameras && cameras.length > 0) {
          this.cameras$.next(cameras);
          this.startScanner();
        } else {
          this.toastService.error('participant.qr-code-scanner.no-camera');
          this.scanError.emit('No camera found');
        }
      })
      .catch((error) => {
        this.toastService.error('participant.qr-code-scanner.no-camera-access');
        this.scanError.emit('Please allow camera access: ' + error);
      });
  }

  private startScanner(cameraId?: string) {
    this.html5QrCode = new Html5Qrcode('reader', {
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      verbose: false,
    });

    // If no camera is selected, use the back camera
    const cameraToUse = cameraId
      ? { deviceId: { exact: cameraId } }
      : { facingMode: 'environment' };

    // Note: Manually setting the aspect ratio leads to errors on some devices
    // For example: Camera cannot be switched
    // OR Safari Desktop camera is zoomed in
    this.html5QrCode
      .start(
        cameraToUse,
        {
          fps: 30,
        },
        (decodedText: string, decodedResult: Html5QrcodeResult) => {
          // do something when code is read
          this.loggerService.logDebug('success', decodedText, decodedResult);
          this.scanCompleted.emit(decodedText);
        },
        (error: string) => {
          // Cannot read QR code error
        }
      )
      .then(() => {
        this.cameras$.value?.forEach((camera) => {
          if (
            camera.id === this.html5QrCode.getRunningTrackSettings().deviceId
          ) {
            this.loggerService.logDebug('selectedCamera', camera);
            this.selectedCamera$.next(camera);
          }
        });
      })
      .catch((err) => {
        // Start failed
        this.toastService.error('participant.qr-code-scanner.start-error');
        this.loggerService.logProdError('Failed to start QR Code Scanner', err);
        this.scanError.emit('Start failed: ' + err);
      });
  }

  public onCameraSelected(
    camera: CameraDevice,
    selectedCamera: CameraDevice
  ): void {
    if (
      selectedCamera?.id === camera?.id ||
      this.html5QrCode.getState() !== Html5QrcodeScannerState.SCANNING
    ) {
      return;
    }

    this.selectedCamera$.next(camera);
    this.html5QrCode
      .stop()
      .then(() => {
        this.html5QrCode.clear();
        this.startScanner(camera.id);
      })
      .catch((error) => {
        this.loggerService.logProdError('ON CAMERA SELECT ERROR: ', error);
      });
  }
}
