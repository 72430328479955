import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { LoggerService } from '../../../../../shared/services/logger.service';

@Component({
  selector: 'participant-qr-code-scanner-modal',
  templateUrl: './qr-code-scanner-modal.component.html',
  styleUrls: ['./qr-code-scanner-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeScannerModalComponent implements OnDestroy {
  @Output() public scanCompleted = new EventEmitter<string>();
  @Output() public closed = new EventEmitter<void>();

  constructor(
    private _modalRef: MDBModalRef,
    private _loggerService: LoggerService
  ) {}

  public ngOnDestroy(): void {
    this.closeModal();
  }

  public cancelButtonClicked(): void {
    this.closeModal();
  }

  public scanErrorHandler(value: string): void {
    this._loggerService.logProdError(value);
    this.closeModal();
  }

  private closeModal(): void {
    this._modalRef.hide();
    this.closed.emit();
  }
}
