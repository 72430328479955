import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';

import { SmartbannerService } from '../../services/smartbanner.service';

@Component({
  selector: 'participant-android-smart-banner',
  templateUrl: './android-smart-banner.component.html',
  styleUrls: ['./android-smart-banner.component.scss'],
})
export class AndroidSmartBannerComponent implements OnInit, OnDestroy {
  @Output() public smartBannerConfirmed = new EventEmitter<void>();
  @Output() public smartBannerClosed = new EventEmitter<void>();

  public appLink: SafeUrl;

  private subscription: Subscription;

  constructor(
    private modalRef: MDBModalRef,
    private smartBannerService: SmartbannerService,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    this.subscription = this.smartBannerService.androidUrl$.subscribe((url) => {
      this.appLink = this.sanitizer.bypassSecurityTrustUrl(url);
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public onCloseClicked(): void {
    this.modalRef.hide();
    this.smartBannerClosed.emit();
  }
}
