import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { ProjectModule } from '../../../shared/modules/project.module';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AndroidSmartBannerComponent } from './components/android-smart-banner/android-smart-banner.component';
import { AppFeedbackModalComponent } from './components/app-feedback-modal/app-feedback-modal.component';
import { MeetingEndedPageComponent } from './components/meeting-ended-page/meeting-ended-page.component';
import { MeetingUpcomingPageComponent } from './components/meeting-upcoming-page/meeting-upcoming-page.component';
import { PinLoginComponent } from './components/pin-login/pin-login.component';
import { QrCodeScannerModalComponent } from './components/qr-code-scanner-modal/qr-code-scanner-modal.component';
import { QrCodeScannerComponent } from './components/qr-code-scanner/qr-code-scanner.component';
import { MeetingUpcomingEndedGuard } from './guards/meeting-upcoming-ended-guard';
import { ParticipantAuthGuard } from './guards/participant-auth-guard';
import { MessageEventService } from './services/message-event.service';

@NgModule({
  imports: [
    AppRoutingModule,
    ProjectModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
  ],
  declarations: [
    // Components
    AppComponent,
    AndroidSmartBannerComponent,
    PinLoginComponent,
    QrCodeScannerComponent,
    QrCodeScannerModalComponent,
    MeetingEndedPageComponent,
    MeetingUpcomingPageComponent,
    AppFeedbackModalComponent,
  ],
  providers: [
    // Guards
    ParticipantAuthGuard,
    MeetingUpcomingEndedGuard,
    // Services
    MessageEventService,
  ],
  exports: [SharedModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
