import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { take } from 'rxjs/operators';
import { MessageType } from '../../../../ionic/src/app/models/iframe-event.model';
import { ParticipantAppFeedbackChoice } from '../../../../shared/models/participant-app-feedback.model';
import { GlobalService } from '../../../../shared/services/global.service';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';
import { LocalizationService } from '../../../../shared/services/localization.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { AppFeedbackModalComponent } from '../components/app-feedback-modal/app-feedback-modal.component';
import { MessageEventService } from './message-event.service';

export interface AppFeedbackData {
  meetingId: string;
  pushToken: string;
  submittedLastFeedback: number;
  lastFeedbackChoice: ParticipantAppFeedbackChoice;
}

@Injectable({
  providedIn: 'root',
})
export class AppFeedbackService {
  private isModalOpen = false;

  constructor(
    private modalService: MDBModalService,
    private localStorageService: LocalStorageService,
    private logger: LoggerService,
    private messageEventService: MessageEventService,
    private localizationService: LocalizationService,
    private globalService: GlobalService
  ) {}

  public openFeedbackModal(appFeedbackData?: AppFeedbackData): void {
    let meetingId: string;
    let pushToken: string;
    let submittedLastFeedback: number;
    let lastFeedbackChoice: ParticipantAppFeedbackChoice;

    if (appFeedbackData) {
      meetingId = appFeedbackData.meetingId;
      pushToken = appFeedbackData.pushToken;
      submittedLastFeedback = appFeedbackData.submittedLastFeedback;
      lastFeedbackChoice = appFeedbackData.lastFeedbackChoice;
    } else {
      meetingId = this.localStorageService.loadMeetingIdFeedback();
      pushToken = this.localStorageService.loadPushToken();
      submittedLastFeedback =
        this.localStorageService.loadSubmittedLastFeedback();
      lastFeedbackChoice = this.localStorageService.loadLastFeedbackChoice();
    }

    if (!meetingId) {
      this.logger.logProdWarn(
        'No meeting id found in local storage. Not opening feedback modal.'
      );
      return;
    }

    if (lastFeedbackChoice === 'sad' || lastFeedbackChoice === 'smile') {
      this.logger.logProd(
        'Last feedback was sad or smile. Not opening feedback modal.'
      );
      return;
    }

    if (submittedLastFeedback) {
      const threshold = moment.utc().subtract(1, 'weeks');
      const submittedLastFeedbackMoment = moment.unix(submittedLastFeedback);
      console.log('Threshold is ', threshold);
      if (submittedLastFeedbackMoment.isAfter(threshold)) {
        this.logger.logProd(
          'Last feedback was submitted less than a week ago. Not opening feedback modal.'
        );
        return;
      }
    }

    if (this.isModalOpen && this.modalService.getModalsCount() > 0) {
      this.logger.logProd(
        'Feedback modal is already open. Not opening feedback modal.'
      );
      return;
    }

    this.isModalOpen = true;

    const modal = this.modalService.show(AppFeedbackModalComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      class:
        'modal-frame modal-full-height col-md-10 modal-notify modal-dialog-scrollable',
      data: {
        meetingId: meetingId,
        pushToken: pushToken,
      },
    });

    modal.content.feedbackSent$
      .pipe(take(1))
      .subscribe((feedbackChoice: ParticipantAppFeedbackChoice) => {
        const now = moment.now();
        this.localStorageService.saveSubmittedLastFeedback(now);
        this.localStorageService.saveLastFeedbackChoice(feedbackChoice);

        const feedbackUrl = this.getFeedbackUrl(feedbackChoice);

        this.localStorageService.removeMeetingIdFeedback();
        this.isModalOpen = false;

        if (this.globalService.isIonicApp()) {
          this.messageEventService.postMessage(
            {
              lastFeedbackSubmitted: now,
              feedbackChoice: feedbackChoice,
              feedbackUrl: feedbackUrl,
            },
            MessageType.FEEDBACK_SUBMITTED
          );
        } else if (feedbackChoice === 'sad' || feedbackChoice === 'smile') {
          window.open(feedbackUrl, '_blank');
        }
      });
  }

  public getFeedbackUrl(feedbackChoice: ParticipantAppFeedbackChoice): string {
    switch (feedbackChoice) {
      case 'smile':
        return this.localizationService.getTranslationForKey(
          'manager.googleReviewURL'
        );
      case 'sad':
        return this.localizationService.getTranslationForKey(
          'manager.negativeFeedbackURL'
        );
      default:
        return '';
    }
  }
}
