import { App } from 'projects/shared/environments/app';

export const environment = {
  app: App.PARTICIPANT_APP,
  production: false,
  apiURL: '/backend/',
  masterURL: 'https://master-bhdev.weptun.de/main/',
  participantURL: 'https://participant-bhdev.weptun.de/',
  hadURL: 'https://had-bhdev.weptun.de',
  websocketURL: 'wss://participant-bhdev.weptun.de/backend/ws',
  playlistBaseUrl: 'https://static.beyond.host/music',
  title: 'Participant App',
  useHeartbeat: false,
  loginPinLength: 8,
  googleReviewURL: 'https://g.page/r/CZyz0If8zf7oEB0/review',
  negativeFeedbackURL:
    'https://docs.google.com/forms/d/e/1FAIpQLSfnGPUYm-qbreMA-rb6FqU9v8M7MyoX5kAzS5Y9klsjvcSSkQ/viewform?usp=sf_link',
};
