<div class="container">
  <div class="row h-25">
    <div class="col h-100 d-flex justify-content-center">
      <img
        src="./../../../assets/BeyondHostLogo.png"
        class="w-100 h-100 object-fit-scale-down"
      />
    </div>
  </div>
  <div class="row h-60">
    <div class="col h-100">
      <app-participant-carousel></app-participant-carousel>
    </div>
  </div>
  <div class="row h-15">
    <div class="col h-100 d-flex justify-content-center p-3">
      <app-back-to-start
        (backPressed)="onBackHomePressed()"
      ></app-back-to-start>
    </div>
  </div>
</div>
