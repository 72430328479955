import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LocalizationService } from 'projects/shared/services/localization.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { MessageType } from '../../../../ionic/src/app/models/iframe-event.model';
import { AuthService } from '../../../../shared/services/auth.service';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { GlobalService } from '../../../../shared/services/global.service';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';
import { VotingService } from '../../../../shared/services/voting.service';
import { MessageEventService } from '../services/message-event.service';

@Injectable({
  providedIn: 'root',
})
export class ParticipantAuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _environmentService: EnvironmentService,
    private _votingService: VotingService,
    private globalService: GlobalService,
    private messageEventService: MessageEventService,
    private localizationService: LocalizationService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<boolean> {
    /*
      Priority:
        1. Previous logged in (this._authService.loggedIn === true)
        2. If MeetingApiKey is not set in the URI => 'checkCredentials()'
        3. Otherwise => 'loginParticipant(meetingId, meetingApiKey)'
    */

    // Priority 1.
    if (this._authService.loggedIn) {
      return of(true);
    }

    // Check if the meetingId is set in the URI
    let meetingId = route.queryParamMap.get('id');
    if (!meetingId) {
      // Check if the meetingId is set in the LocalStorage
      if (this._environmentService.checkSettingsParticipant()) {
        meetingId = this._localStorageService.loadMeeting();
      } else {
        if (this.globalService.isIonicApp()) {
          return this.handleErrorForIonicApp();
        } else {
          const errorMsg = this.localizationService.getTranslationForKey(
            'participant.rescan-qr-code'
          );

          // meetingId is neither stored nor part of the URI
          this._router.navigate(['/error'], {
            queryParams: { errorMsg },
          });
          return of(false);
        }
      }

      if (!meetingId) {
        return of(false);
      }
    } else {
      // Save new meetingId in LocalStorage
      this._environmentService.saveSettingsParticipant(meetingId);
    }
    const meetingApiKey = route.queryParamMap.get('key');

    // Priority 2.
    if (!meetingApiKey) {
      return this.validateAuthentication(meetingId, route);
    }

    // Priority 3.
    return this._authService.loginParticipant(meetingId, meetingApiKey).pipe(
      switchMap(() => {
        return this.validateAuthentication(meetingId, route);
      }),
      catchError((error) => {
        return this.handleError(error, route);
      })
    );
  }

  private validateAuthentication(
    meetingId: string,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    this._localStorageService.saveMeeting(meetingId);
    return forkJoin([
      this._environmentService.getRoomForMeeting(),
      this._authService.checkCredentials(),
      this._environmentService.getMeetingBasic(meetingId),
    ]).pipe(
      map(([room, credentials, basicMeeting]) => {
        this._localStorageService.saveMeetingIdFeedback(meetingId);
        this._authService.loggedIn = true;
        this._environmentService.startWebSocketConnection(false);
        if (credentials && credentials.meetingId) {
          this._environmentService.participantCredentials = credentials;
        }
        this._votingService.startVotingWebSocketConnection(true);
        this.messageEventService.postMessage(
          { success: true, credentials },
          MessageType.LOGIN
        );
        this._environmentService.setMeetingName(
          basicMeeting?.name ?? room.name
        );
        return true;
      }),
      catchError((error) => {
        return this.handleError(error, route);
      })
    );
  }

  private handleError(
    error: any,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    let href = '';
    switch (error.status) {
      case 302:
        href = this.handle302(error);
        break;
      case 403:
        href = '';
        break;
    }
    // Meeting has ended status code
    if (error.status === 420) {
      href = '/meeting-ended';
    } else {
      // 401 handled in ApplicationHttpClient.
    }
    if (this.globalService.isIonicApp()) {
      return this.handleErrorForIonicApp(href);
    }

    this._router.navigate([href]);
    return of(false);
  }

  private handle302(err: HttpErrorResponse) {
    this._localStorageService.saveMeeting(err.error.id);
    this._localStorageService.saveMeetingStartDate(err.error.startDate);
    return '/meeting-upcoming';
  }

  private handleErrorForIonicApp(href?: string): Observable<boolean> {
    this.messageEventService.postMessage(
      { success: false, href: href },
      MessageType.LOGIN
    );
    return of(false);
  }
}
