<div #container id="reader-container">
  <div id="reader"></div>
  <ng-container *ngIf="cameras$ | async as cameras">
    <ng-container *ngIf="selectedCamera$ | async as selectedCamera">
      <select
        id="camera-select"
        [ngModel]="selectedCamera"
        (ngModelChange)="onCameraSelected($event, selectedCamera)"
      >
        <option *ngFor="let camera of cameras" [ngValue]="camera">
          {{ camera.label }}
        </option>
      </select>
    </ng-container>
  </ng-container>
</div>
