<div class="modal-content">
  <div class="modal-android">
    <div class="d-flex w-100 h-100 justify-content-between align-items-center">
      <div class="fixed-position align-self-start">
        <button
          type="button"
          aria-label="Close"
          class="close-left"
          (click)="onCloseClicked()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="text-label">{{ 'participant.open-in-app' | translate }}</div>
      <div class="fixed-position">
        <a
          class="btn open-button btn-sm ml-0"
          type="button"
          aria-label="Open"
          mdbBtn
          mdbWavesEffect
          [href]="appLink"
          (click)="smartBannerConfirmed.emit()"
        >
          {{ 'global.open' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
