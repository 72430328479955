import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { sharedRoutes } from '../../../shared/routes/route.routing';
import { PinLoginComponent } from './components/pin-login/pin-login.component';
import { ParticipantAuthGuard } from './guards/participant-auth-guard';
import { MeetingEndedPageComponent } from './components/meeting-ended-page/meeting-ended-page.component';
import { MeetingUpcomingPageComponent } from './components/meeting-upcoming-page/meeting-upcoming-page.component';
import { MeetingUpcomingEndedGuard } from './guards/meeting-upcoming-ended-guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        loadChildren: () =>
          import('./modules/main/main.module').then((m) => m.MainModule),
        canActivate: [ParticipantAuthGuard],
      },
      {
        path: 'meeting-upcoming',
        component: MeetingUpcomingPageComponent,
        pathMatch: 'full',
        canActivate: [MeetingUpcomingEndedGuard],
      },
      {
        path: 'meeting-ended',
        component: MeetingEndedPageComponent,
        pathMatch: 'full',
        canActivate: [MeetingUpcomingEndedGuard],
      },
      {
        path: '',
        component: PinLoginComponent,
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'main',
        pathMatch: 'full',
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot([...sharedRoutes, ...routes], {
      // Do only tracing use for debugging
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
