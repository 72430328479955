<div class="container">
  <div class="row h-25">
    <div class="col h-100 d-flex justify-content-center">
      <img
        src="./../../../assets/BeyondHostLogo.png"
        class="w-100 h-100 object-fit-scale-down"
      />
    </div>
  </div>
  <div class="row max-h-50">
    <div class="col h-100">
      <form
        #locationsForm="ngForm"
        id="no-validation-form"
        class="text-center"
        (ngSubmit)="joinMeeting()"
      >
        <div class="md-form">
          <input
            type="text"
            id="pin"
            name="pin"
            #pinForm="ngModel"
            class="form-control"
            mdbInput
            [(ngModel)]="pinAsNumber"
            required
            [pattern]="'^[0-9]{' + PIN_LENGTH + '}$'"
            [minlength]="PIN_LENGTH"
            [maxlength]="PIN_LENGTH"
            inputmode="numeric"
          />
          <label for="pin">
            {{ 'participant.start-page.pin' | translate }}
            <span
              [ngClass]="{
                'text-color-invalid-field': pinForm.errors,
                'text-color-valid-field': !pinForm.errors
              }"
            >
              *
            </span>
          </label>
          <mdb-error
            class="mt-3"
            *ngIf="pinForm.invalid && (pinForm.dirty || pinForm.touched)"
          >
            {{ 'participant.start-page.pin-info' | translate }}
          </mdb-error>
        </div>

        <i
          *ngIf="isCheckingPin"
          class="fa fa-2x fa-spinner fa-spin"
          style="color: var(--custom-blue)"
        ></i>

        <button
          mdbBtn
          type="submit"
          class="btn custom-btn w-100 mx-0 my-0 py-2"
          mdbWavesEffect
        >
          {{ 'participant.start-page.pin-btn' | translate | uppercase }}
        </button>

        <div class="d-flex flex-column">
          <span class="my-2">{{ 'global.or' | translate }}</span>

          <button
            mdbBtn
            type="button"
            class="btn custom-btn w-100 mx-0 my-0 py-2"
            mdbWavesEffect
            (click)="scanQrCode()"
          >
            {{ 'participant.start-page.scan-qr-code' | translate | uppercase }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="row h-25">
    <div class="col h-100 pt-4 pb-3 text-center bottom-text">
      <span>
        {{ 'participant.start-page.visit-us' | translate | uppercase }}:
      </span>
      <br />
      <a href="https://beyond.host/" target="_blank">https://beyond.host</a>
    </div>
  </div>
</div>
