import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

import { EnvironmentService } from '../../../../shared/services/environment.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SmartbannerService {
  private meetingId: string;
  private meetingApiKey: string;

  private baseUrlAndroid = '';

  public androidUrl$ = new BehaviorSubject('');

  constructor(
    private metaService: Meta,
    private environmentService: EnvironmentService
  ) {
    // Replace http scheme for android
    this.baseUrlAndroid = this.replaceHttpSchemeForAndroid(
      environment.participantURL
    );
  }

  private replaceHttpSchemeForAndroid(url: string): string {
    return url.includes('https://') || url.includes('http://')
      ? url.replace(/^https?:\/\//, '')
      : url.replace(/^http?:\/\//, '');
  }

  public setAndroidUrl(pathSegment: string): void {
    const base = this.addCredentialUrlParams(
      `${this.baseUrlAndroid}${pathSegment}`,
      true
    );

    this.androidUrl$.next(
      `intent://${base}#Intent;scheme=beyondhost;package=host.beyond.participant.android;end`
    );
  }

  public createSmartBanner(pathSegment: string): void {
    if (this.meetingId == null || this.meetingApiKey) {
      const credentials = this.environmentService.participantCredentials;
      this.meetingId = credentials?.meetingId;
      this.meetingApiKey = credentials?.meetingApiKey;
    }
    this.updateSmartBanner(pathSegment);
  }

  private updateSmartBanner(pathSegment: string): void {
    this.metaService.updateTag(
      this.constructMetaTag(pathSegment),
      "name='apple-itunes-app'"
    );
    this.setAndroidUrl(pathSegment);
  }

  private constructMetaTag(pathSegment: string): MetaDefinition {
    const appArgumentUrl = this.addCredentialUrlParams(
      `${environment.participantURL}${pathSegment}`,
      false
    );

    return {
      name: 'apple-itunes-app',
      content: 'app-id=1600924357, app-argument=' + appArgumentUrl,
    };
  }

  private addCredentialUrlParams(baseUrl: string, android: boolean): string {
    if (!baseUrl || baseUrl.length == 0) {
      return baseUrl;
    }

    if (
      android &&
      !baseUrl.includes('https://') &&
      !baseUrl.includes('http://')
    ) {
      // Add HTTP scheme temporarily to be able to construct a valid URL object.
      baseUrl = 'http://' + baseUrl;
    }

    // Use the URL object to properly append or replace
    // credentials parameters if they already exist.
    // For this we need a valid HTTP scheme (see above).
    const url = new URL(baseUrl);

    // Get credentials.
    const credentials = this.environmentService.participantCredentials;
    this.meetingId = credentials?.meetingId;
    this.meetingApiKey = credentials?.meetingApiKey;

    // Add URL credentials as parameters if they does exist.
    this.meetingId?.length > 0
      ? url.searchParams.set('id', this.meetingId)
      : {};
    this.meetingApiKey?.length > 0
      ? url.searchParams.set('key', this.meetingApiKey)
      : {};

    if (android) {
      // Remove HTTP scheme again for android URL string
      // which should not have the HTTP scheme.
      return this.replaceHttpSchemeForAndroid(url.href);
    }

    return url.href;
  }
}
