import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ParticipantAppFeedback,
  ParticipantAppFeedbackChoice,
} from '../../../../../shared/models/participant-app-feedback.model';
import { BhToastService } from '../../../../../shared/services/bh-toast.service';
import { GlobalService } from '../../../../../shared/services/global.service';
import { LoggerService } from '../../../../../shared/services/logger.service';
import { ParticipantService } from '../../../../../shared/services/participant.service';

@Component({
  selector: 'app-participant-app-feedback-modal',
  templateUrl: './app-feedback-modal.component.html',
  styleUrls: ['./app-feedback-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFeedbackModalComponent {
  public meetingId: string;
  public pushToken?: string;

  public feedbackSent$: Subject<ParticipantAppFeedbackChoice> = new Subject();

  public activeSmiley: boolean[] = Array(2).fill(false);
  public noSmileySelected = true;

  constructor(
    private modalRef: MDBModalRef,
    private participantService: ParticipantService,
    private toastService: BhToastService,
    private logger: LoggerService,
    private globalService: GlobalService
  ) {}

  public smileyPressed(idx: number): void {
    const active = this.activeSmiley.indexOf(true);
    if (active !== -1 && active !== idx) {
      this.activeSmiley[active] = false;
    }
    this.activeSmiley[idx] = !this.activeSmiley[idx];
    this.noSmileySelected = this.activeSmiley.indexOf(true) === -1;
  }

  public submitFeedback(): void {
    if (this.activeSmiley.indexOf(true) === -1) {
      this.toastService.warning('participant.select-emoji');
      return;
    }
    const feedbackChoice = this.smileyIndexToString(
      this.activeSmiley.indexOf(true)
    );

    this.sendFeedback(feedbackChoice);
  }

  public dismissFeedback(): void {
    this.sendFeedback('no feedback', false);
  }

  private closeModal(): void {
    this.modalRef.hide();
  }

  private sendFeedback(
    feedbackChoice: ParticipantAppFeedbackChoice,
    showError = true
  ): void {
    if (!this.meetingId) {
      console.error('No meetingId provided!');
      return;
    }

    const feedback: ParticipantAppFeedback = {
      meetingId: this.meetingId,
      feedbackChoice: feedbackChoice,
      pushToken: this.pushToken,
    };

    this.participantService
      .sendAppFeedback(feedback)
      .pipe(take(1))
      .subscribe(
        () => {
          this.feedbackSent$.next(feedbackChoice);
          this.closeModal();
        },
        (err: HttpErrorResponse) => {
          this.logger.logProdError(err);
          if (showError) {
            this.toastService.error('participant.app-feedback.error');
          } else {
            this.closeModal();
          }
        }
      );
  }

  private smileyIndexToString(idx: number): ParticipantAppFeedbackChoice {
    switch (idx) {
      case 0:
        return 'smile';
      case 1:
        return 'sad';
      default:
        return 'no feedback';
    }
  }
}
